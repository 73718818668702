import { useEffect, useState } from 'react';
import { Button, SwipeableCustomerDrawer } from '@qlub-dev/qlub-kit';
import { useTranslation } from '@clubpay/customer-common-module/src/hook/translations';
import { Grid, TextField } from '@mui/material';
import { useVendor } from '@clubpay/customer-common-module/src/context/vendor';

interface IProps {
    value: string;
    onChange: (val: string) => void;
}

const QSRQrPasscode = ({ value, onChange }: IProps) => {
    const { t } = useTranslation('common');
    const [open, setOpen] = useState(false);
    const [passcode, setPasscode] = useState('');
    const { vendor } = useVendor();

    const closeModalHandler = () => {
        setOpen(false);
    };

    const openModalHandler = () => {
        setOpen(true);
    };

    useEffect(() => {
        if (!vendor || !(vendor.orderConfig as any).qrPasscodeEnable || window.location.href.includes('sim=true')) {
            return;
        }
        if (value) {
            closeModalHandler();
        } else {
            openModalHandler();
        }
    }, [value, vendor]);

    const applyHandler = () => {
        onChange(passcode);
    };

    return (
        <SwipeableCustomerDrawer
            headerTitle={t('Enter QR passcode')}
            open={open}
            onClose={closeModalHandler}
            onOpen={openModalHandler}
            disablePortal
            disableSwipeToOpen
            PaperProps={{
                sx: {
                    maxWidth: '552px',
                    margin: '0 auto',
                    background: '#fff',
                    borderRadius: '30px 30px 0 0',
                },
            }}
        >
            <Grid container spacing={2} sx={{ pt: '16px' }}>
                <Grid item xs={12}>
                    <TextField
                        label={t('Passcode')}
                        inputProps={{
                            sx: {
                                textAlign: 'center',
                                letterSpacing: '1.7em',
                                fontWeight: 'bold',
                            },
                        }}
                        fullWidth
                        value={passcode}
                        onChange={(e) => {
                            setPasscode(e.target.value);
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Button variant="contained" size="large" fullWidth type="submit" onClick={applyHandler}>
                        {t('Apply')}
                    </Button>
                </Grid>
            </Grid>
        </SwipeableCustomerDrawer>
    );
};

export default QSRQrPasscode;
