import { OrderPriceRoundingModeEnum } from '@clubpay/customer-common-module/src/repository/vendor';
import { ceil, floor, round } from 'lodash';

export const kRoundNumber = (
    amount: string | number,
    precision: number,
    rounding: OrderPriceRoundingModeEnum = OrderPriceRoundingModeEnum.Round,
): number => {
    if (typeof amount === 'string') {
        amount = Number(amount);
    }
    switch (rounding) {
        default:
        case OrderPriceRoundingModeEnum.Round:
            return round(amount, precision);
        case OrderPriceRoundingModeEnum.Floor:
            return floor(amount, precision);
        case OrderPriceRoundingModeEnum.Ceil:
            return ceil(amount, precision);
    }
};
