import { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import QSRQrPasscode from '@/components/QSR/QSRQrPasscode';
import { useQlubRouter } from '@clubpay/customer-common-module/src/hook/router';
import { IQrPasscodeContext } from './types';

const QrPasscodeContext = createContext<IQrPasscodeContext>({ passcode: '', setPasscode: () => console.log });

interface Props {
    children?: ReactNode;
}

export function QrPasscodeContextProvider({ children }: Props) {
    const { pathname, query, routerReplace } = useQlubRouter();
    const { qrpc } = query as unknown as { qrpc?: string };
    const [passcode, setPasscode] = useState(qrpc || '');

    useEffect(() => {
        if (qrpc === passcode) {
            return;
        }
        setPasscode(qrpc || '');
    }, [qrpc]);

    const setPasscodeHandler = (val: string) => {
        routerReplace(pathname, {
            ...query,
            qrpc: val,
        });
        setPasscode(val);
    };

    return (
        <QrPasscodeContext.Provider value={{ passcode, setPasscode: setPasscodeHandler }}>
            <QSRQrPasscode value={passcode} onChange={setPasscodeHandler} />
            {children}
        </QrPasscodeContext.Provider>
    );
}

export const useQrPasscode = () => useContext(QrPasscodeContext);
