import { useEffect, useState } from 'react';
import { setLocale } from 'yup';
import * as ja from 'yup-locale-ja';
import { ThemeProvider } from '@mui/material/styles';
import { AppCacheProvider } from '@mui/material-nextjs/v14-pagesRouter';
import themeRtl from '@/theme/rtl';
import { detectRTLFromLang } from '@clubpay/customer-common-module/src/utility/k_lang';
import { useQlubRouter } from '@clubpay/customer-common-module/src/hook/router';
import getTheme from './getTheme';

interface IProps {
    children: any;
}

const CustomThemeProvider = ({ children }: IProps) => {
    const { url, lang } = useQlubRouter();
    const [rtl, setRtl] = useState(false);
    const { theme } = getTheme();

    useEffect(() => {
        if (!lang) {
            return;
        }
        const isRtl = detectRTLFromLang(lang);
        const el = document.querySelector('html');
        if (el) {
            el.setAttribute('dir', isRtl ? 'rtl' : 'ltr');
        }
        const elBody = document.querySelector('body');
        if (elBody) {
            elBody.setAttribute('dir', isRtl ? 'rtl' : 'ltr');
        }
        setRtl(isRtl);
    }, [lang]);

    useEffect(() => {
        if (url.cc === 'jp') {
            setLocale(ja.suggestive);
        }
        const el = document.querySelector('html');
        if (el) {
            el.setAttribute('lang', url.cc || 'ae');
        }
    }, [url.cc]);

    return (
        <AppCacheProvider>
            <ThemeProvider theme={rtl ? themeRtl(theme) : theme}>{children}</ThemeProvider>
        </AppCacheProvider>
    );
};

export default CustomThemeProvider;
