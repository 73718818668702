/*
    Creation Time: 2022 - April - 18
    Created by:  (hamidrezakk)
    Maintainers:
       1.  HamidrezaKK (hamidrezakks@gmail.com)
    Auditor: HamidrezaKK
    Copyright Qlub_ 2022
*/

import { createTheme, Theme } from '@mui/material/styles';

// Create a theme instance.
const theme = (mainTheme: Theme) =>
    createTheme({
        ...mainTheme,
        direction: 'rtl',
        typography: {
            fontFamily: `-apple-system, "system-ui", "Segoe UI", Roboto`,
        },
    });

export default theme;
