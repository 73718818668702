import { useEffect } from 'react';

const minKeyboardHeight = 300;

export const isKeyboardOpen = () => {
    return document.getElementsByTagName('html')?.[0]?.getAttribute('keyboard') === 'open';
};

const menuRoute = [
    '/qr/[cc]/[slug]/[id]/[f1]/[f2]/[hash]/legacy_menu',
    '/qr/[cc]/[slug]/[id]/[f1]/[f2]/[hash]/menu_with_payment',
    '/qr/[cc]/[slug]/[id]/[f1]/[f2]/[hash]/menu_without_payment',
    '/qr/[cc]/[slug]/[id]/[f1]/[f2]/[hash]/menu',
];

const useWindowResize = (route?: string) => {
    useEffect(() => {
        // window resize causes zoom bug ios safari
        if (menuRoute.includes(route || '')) {
            return;
        }
        const qlubScreen: any = window.visualViewport || window;
        const listener = () => {
            const vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty('--vh', `${vh}px`);
            const vvh = (window.visualViewport?.height || window.innerHeight) * 0.01;
            document.documentElement.style.setProperty('--vvh', `${vvh}px`);
            const open =
                window.screen.height - minKeyboardHeight > (window?.visualViewport?.height || window.innerHeight || 0);
            document.getElementsByTagName('html')?.[0]?.setAttribute('keyboard', open ? 'open' : 'close');
        };
        qlubScreen.addEventListener('resize', listener);
        return () => {
            qlubScreen.removeEventListener('resize', listener);
        };
    }, [route]);
};

export default useWindowResize;
